@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&display=swap");
@import "react-alice-carousel/lib/alice-carousel.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
body {
  font-family: "Nunito", sans-serif;
  font-family: "Roboto", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif !important;
}
img {
  max-width: 100%;
}

.flex {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.StyledModal .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.ant-menu-title-content::after {
  display: none !important;
}

.StyledModal .ant-modal-content {
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

* {
  padding: 0;
  margin: 0;
}
hr {
  height: 2px;
  background-color: #d7d9e2;
  outline: 0;
  border: 0;
}
.padding-y-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.padding-y-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.padding-y-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.padding-y-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.padding-y-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.padding-x-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.padding-x-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.padding-x-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.padding-x-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.padding-x-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.margin-0 {
  margin: 0 !important;
}
.margin-x-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.margin-x-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.margin-x-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.margin-x-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.margin-x-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.margin-y-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.margin-y-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.margin-y-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.margin-y-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.margin-y-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.border-radius-10 {
  border-radius: 10px !important;
}
.border-radius-15 {
  border-radius: 15px !important;
}
.border-radius-20 {
  border-radius: 20px !important;
}
.border-rounded {
  border-radius: 50% !important;
}
.relative {
  position: relative;
}
.goldencolor {
  color: #c19039 !important;
}
.mousepointer {
  cursor: pointer;
}
.mainbtn {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a77721+55,d5af68+93,d5af68+93 */
  background: #a77721; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a77721', endColorstr='#d5af68',GradientType=1 ); /* IE6-9 */
  color: #fff;
  border: 0;
  padding: 8px 20px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  height: auto;
  background-size: 300% 100%;
  background-position: 100% 0;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.mainbtn:hover {
  opacity: 0.8;
  background-position: 100%;
  color: #fff !important;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a77721+0,d5af68+23 */
  background: #a77721; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #a77721 0%,
    #d5af68 23%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #a77721 0%,
    #d5af68 23%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #a77721 0%,
    #d5af68 23%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a77721', endColorstr='#d5af68',GradientType=1 ); /* IE6-9 */

  box-shadow: 0 4px 15px 0 rgba(167, 119, 33, 0.75);
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.top {
  width: 100%;
  text-align: right;
  padding: 10px 0;
  color: #fff;
  font-size: 20px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.top .mainbtn {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a77721+55,d5af68+93,d5af68+93 */
  background: #a77721; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a77721', endColorstr='#d5af68',GradientType=1 ); /* IE6-9 */
  color: #fff;
  border: 0;
  padding: 5px 15px;
  font-size: 12px;
  font-family: "Nunito", sans-serif;
  height: auto;
  margin: 0 10px;
}
.top svg {
  margin: 0 5px;
  background: #fff;
  color: #000;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.navigation {
  position: absolute;
  width: 100%;
  z-index: 999;
}
.logo {
  width: 80px !important;
}
.banner {
  /* background: url("/public/images/bannermain.png") no-repeat; */
  background-size: cover;
  text-align: center;
  background-attachment: fixed;
  padding: 200px 0 350px;
}

.authFormBox {
  padding: 40px 20px;
}

.navigation1 {
  background: url("/public/images/bannermain.png") no-repeat;
  background-size: cover;
  text-align: center;
  padding: 10px 0 10px;
}
.banner h1 {
  color: #fff;
  font-size: 80px;
  font-weight: 900;
  margin: 0 0 25px;
}
.banner p {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 35px;
}
span.anticon.anticon-ellipsis {
  color: #fff;
}
.bannercards {
  background: #fff;
  border: 0;
  border-radius: 15px;
  -webkit-box-shadow: 0px 15px 18px 0px #ccc;
  box-shadow: 0px 15px 18px 0px #ccc;
  margin: -65px 0 45px;
  text-align: center;
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannercards:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#af812e+3,cea75e+100 */
  background: #af812e; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #af812e 3%,
    #cea75e 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #af812e 3%,
    #cea75e 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #af812e 3%,
    #cea75e 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#af812e', endColorstr='#cea75e',GradientType=1 ); /* IE6-9 */
  color: #fff;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.bannercards2 {
  -webkit-box-shadow: 0px 15px 18px 0px #ccc;
  box-shadow: 0px 15px 18px 0px #ccc;
  margin: 45px 0;
  text-align: center;
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bannercards3 {
  -webkit-box-shadow: 0px 15px 18px 0px #ccc;
  box-shadow: 0px 15px 18px 0px #ccc;
  margin: 45px 0;
  text-align: center;
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bannercards2:hover,
.bannercards3:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#af812e+3,cea75e+100 */
  background: #af812e; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #af812e 3%,
    #cea75e 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #af812e 3%,
    #cea75e 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #af812e 3%,
    #cea75e 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#af812e', endColorstr='#cea75e',GradientType=1 ); /* IE6-9 */
  color: #fff;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.bannercards img,
.bannercards2 img,
.bannercards3 img {
  margin: 0 0 15px;
  width: 90px !important;
}
.mainMenu.ant-menu-horizontal {
  border-bottom: 0;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
}

.fontFamily1 {
  font-family: "Nunito", sans-serif !important;
}

.mainMenu.ant-menu-light {
  background: transparent;
}
.mainMenu.ant-menu-horizontal .ant-menu-item,
.mainMenu.ant-menu-horizontal .ant-menu-item:hover,
.mainMenu.ant-menu-horizontal
  .ant-menu-light
  .ant-menu-item:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  color: #fff !important;
}
.mainMenu.ant-menu-horizontal .ant-menu-item:hover::after {
  border-bottom-color: #fff !important ;
}
:where(.css-dev-only-do-not-override-1wazalj).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1wazalj).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  background-color: #d5af68 !important;
  color: #fff !important;
}
.profile {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0 10px;
}
.profile img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.profile_btn {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0 10px;
  display: block;
}
.banner .alice-carousel__stage-item *,
.eventsarea .alice-carousel__stage-item *,
.featuredarea .eventsarea .alice-carousel__stage-item * {
  padding: 0 5px;
}
.banner .alice-carousel,
.eventsarea .alice-carousel,
.featuredarea .alice-carousel {
  margin: 85px auto;
}
.banner .alice-carousel__stage-item:nth-child(odd),
.eventsarea .alice-carousel__stage-item:nth-child(odd),
.featuredarea .alice-carousel__stage-item:nth-child(odd) {
  margin-top: 45px;
}
.banner .anticon svg {
  color: #fff;
}
.videoarea {
  padding: 150px 0;
  align-items: center;
  background: url("/public/images/videoareabg.png") no-repeat -100px center;
}

.subscriptionarea {
  padding: 150px 0 0;
  align-items: center;
  background: url("/public/images/leftline.png") no-repeat -108px top,
    url("/public/images/rightline.png") no-repeat 108% bottom;
}
.videoarea h2,
.subscriptionarea h2 {
  font-size: 60px;
  margin: 0 0 25px;
  text-transform: uppercase;
}
.subscriptionarea .mainbtn {
  margin-bottom: 80px;
}
.videoarea p,
.subscriptionarea p {
  font-size: 18px;
  margin: 0 0 35px;
}
.eventsarea {
  background: #1c2028;
  padding: 50px 0;
}
.eventsarea h2 {
  font-size: 30px;
  margin: 0 0 45px;
  text-transform: uppercase;
  color: #fff;
}
.eventsarea h3,
.whitebg h4 {
  font-size: 20px;
  margin: 15px 0;
  text-transform: uppercase;
  color: #fff;
}
.whitebg h4 {
  color: #000;
}
.eventsarea p {
  font-size: 18px;
  margin: 0 0 10px;
  color: #fff;
}
.eventsarea span {
  font-size: 14px;
  margin: 0 0 10px;
  color: #d5af68;
}
.featuredarea {
  padding: 80px 0;
  background: #f8f8f8;
}
.featuredarea h2,
.whitebg h2 {
  color: #000000;
  font-size: 50px;
  margin: 0 0 15px;
  text-transform: uppercase;
  text-align: center;
}
.banner h2 {
  color: #fff;
}

.banner a {
  color: #d5af68;
}
.featuredarea h2 svg,
.whitebg h2 svg {
  color: #000000;
  font-size: 25px;
  float: left;
  margin-top: 15px;
}
.banner h2 svg {
  color: #fff;
}
.featuredarea p,
.whitebg p {
  color: #000000;
  font-size: 18px;
  margin: 0 0 15px;
  text-align: center;
}
.banner p {
  color: #fff;
}
.featuredarea .item {
  text-align: center;
}
.featuredarea h3 {
  color: #d5af68;
  font-size: 20px;
  margin: 15px 0;
  text-transform: uppercase;
}
.featuredarea .item span {
  font-size: 14px;
  margin: 0 0 10px;
  color: #000;
  text-transform: uppercase;
}
.rightarea {
  background: #1c2028;
  padding: 50px 0;
}
.rightarea h2 {
  font-size: 36px;
  margin: 0 0 30px;
  color: #fff;
  text-transform: uppercase;
}
.rightarea h4 {
  font-size: 24px;
  line-height: 20px;
  margin: 0 0 10px 15px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}
.rightarea p {
  font-size: 14px;
  margin: 0 0 10px 15px;
  color: #fff;
  font-weight: 400;
}
.iconarea {
  margin: 0 0 50px;
  align-items: center;
}
.iconarea img {
  background-color: #15181f;
  padding: 8px;
  border-radius: 50%;
}
.footer {
  background: #1c2028;
  padding: 80px 0 50px;
}
.footer img {
  margin: 0 0 25px;
}
.footer h3 {
  font-size: 20px;
  margin: 0 0 15px;
  color: #bf9343;
  font-weight: 400;
}
.footer p {
  font-size: 14px;
  margin: 0 0 25px;
  color: #fff;
  font-weight: 400;
}
.footer ul li {
  font-size: 14px;
  margin: 0 0 15px;
  color: #fff;
  font-weight: 400;
  list-style-type: none;
  cursor: pointer;
}
.footer ul li a {
  color: #fff;
  text-decoration: none;
}
.bottom {
  border-top: 2px solid #5c5e64;
  padding: 25px 0 0;
  text-align: center;
  margin: 50px 0 0;
}
.whitebg {
  padding: 85px 0;
}
.whitebg :where(.css-dev-only-do-not-override-1wazalj).ant-checkbox-wrapper {
  margin-top: 20px;
}
.whitebg
  :where(.css-dev-only-do-not-override-1wazalj).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner,
.whitebg
  :where(.css-dev-only-do-not-override-1wazalj).ant-checkbox-checked
  .ant-checkbox-inner {
  background: #af812e !important;
  border-color: #af812e !important;
}
.info-area {
  align-items: center;
}
.info-area h3 {
  font-size: 24px;
  text-align: left;
  margin: 0 0 25px;
  text-transform: uppercase;
  font-weight: 800;
}
.info-area p {
  font-size: 16px;
  text-align: left;
  margin: 0 0 15px;
}
.abouticon {
  position: absolute;
  top: 0;
  width: 120px;
  height: 120px;
  margin: auto;
  bottom: 0;
  right: -10%;
}
.greybg {
  background: #f8f8f8;
}
.greybg h5 {
  font-size: 16px;
  text-align: left;
  margin: 0 0 10px;
  text-transform: uppercase;
  font-weight: 800;
}
.postimage {
  width: 100%;
}
.postimage img {
  height: 300px !important;
  object-fit: cover;
  border-radius: 20px;
}
.gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a77721+55,d5af68+93,d5af68+93 */
  background: #a77721; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a77721', endColorstr='#d5af68',GradientType=1 ); /* IE6-9 */
  color: #fff;
  cursor: pointer;
  border: 0;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 0 0 10px 10px;
  display: flex;
  font-family: "Nunito", sans-serif;
  height: auto;
  background-size: 300% 100%;
  background-position: 100% 0;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.gradient svg ~ span {
  margin-left: 10px;
}
.sharingarea {
  justify-content: start;
}
.sharingarea span {
  margin: 0 10px;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  vertical-align: top;
}
.uploadbtn {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
}
.tnc p {
  color: #000000;
  font-size: 16px;
  margin: 0 0 25px;
  line-height: 28px;
  font-weight: 300;
}
.pricebox {
  position: relative;
  margin: 65px 0 0;
}
/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #000;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #ff0000;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.silverbox {
  background: rgb(255, 109, 0);
  background: linear-gradient(
    138deg,
    rgba(203, 162, 88, 1) 35%,
    rgba(226, 180, 98, 1) 35%,
    rgba(226, 180, 98, 1) 60%,
    rgba(203, 162, 88, 1) 60%
  );
  padding: 90px 30px 40px 30px;
  color: #fff;
  border-radius: 15px;
}
.blackbox {
  background: rgb(34, 34, 34);
  background: linear-gradient(
    138deg,
    rgba(34, 34, 34, 1) 35%,
    rgba(38, 38, 38, 1) 35%,
    rgba(38, 38, 38, 1) 60%,
    rgba(34, 34, 34, 1) 60%
  );
  padding: 90px 30px 40px 30px;
}
.silverbox h4 {
  font-size: 36px;
  color: #fff;
  margin: 0 0 25px;
  text-align: center;
}
.silverbox svg {
  margin-right: 10px;
}
.silverbox .ant-spin-nested-loading {
  margin-bottom: 25px;
}
.whiteanchor {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.silverbox .ant-list-item,
.silverbox .ant-list-header {
  border-block-end: 0px;
  color: #fff;
}
.lockedbook {
  border: 2px solid #ccc;
  padding: 20px;
  text-align: center;
  margin-bottom: 25px;
  position: relative;
}
.lockedbook img {
  max-width: 100%;
}
.lockedbook button span {
  color: #fff;
  margin: 0;
}
.locked {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.locked svg {
  color: #fff;
  font-size: 44px;
}
.shadowwhitebg {
  background: #fff;
  box-shadow: 0px 0px 6px 0px #cecece;
  margin-left: -80px;
  box-sizing: border-box;
}
.topics {
  background: #fff;
  box-shadow: 0px 2px 10px 4px #cecece;
  margin: 10px 0 25px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topics p {
  margin: 0;
  text-align: left;
}
.librarydetails {
  background: url("/public/images/videoareabg.png") no-repeat center center;

  box-shadow: 0px 2px 10px 4px #cecece;
  padding: 25px;
  box-sizing: border-box;
}
.librarydetails p {
  text-align: left;
  color: #666666;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}
.purchasebtn {
  color: #00ccb7;
  text-decoration: underline;
}
.upcomingimage .ant-image {
  display: block !important;
  border-radius: 15px;
}
.datetag {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a77721+55,d5af68+93,d5af68+93 */
  background: #a77721; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a77721', endColorstr='#d5af68',GradientType=1 ); /* IE6-9 */
  text-align: center;
  padding: 25px;
  width: 75px;
  height: 75px;
  border-radius: 10px;
  position: absolute;
  z-index: 99;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.datetag h6 {
  color: #fff;
  font-size: 40px;
  font-weight: 800;
  line-height: 35px;
}
.datetag span {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  display: block;
}
.alice-carousel__dots-item:not(.__custom) {
  background: #c1c1c1;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.alice-carousel__dots-item:not(.__custom):hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a77721+55,d5af68+93,d5af68+93 */
  background: #a77721; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a77721', endColorstr='#d5af68',GradientType=1 ); /* IE6-9 */
}
.alice-carousel__dots-item:not(.__custom).__active {
  width: 50px;
  border-radius: 252px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a77721+55,d5af68+93,d5af68+93 */
  background: #a77721; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a77721', endColorstr='#d5af68',GradientType=1 ); /* IE6-9 */
}

.home-video-box {
  padding-top: 0 !important;
  overflow: hidden;
}

.home-video-box .video-react-video {
  width: 100%;
}

.home-video-box .video-react-control-bar {
  display: none;
}

.home-video-box .video-react-big-play-button {
  display: none;
}
.whitebg .greybg :where(.css-dev-only-do-not-override-1wazalj).ant-image {
  display: block;
}
:where(.css-dev-only-do-not-override-1wazalj).ant-modal .ant-modal-body h4 {
  color: #1e1919;
  font-size: 22px;
  font-weight: 800;
  text-align: center;
  margin: 0 0 25px;
  text-transform: uppercase;
}
.modalinner {
  margin-bottom: 25px;
}
.modalinner p {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 10px;
  text-transform: uppercase;
}
.modalinner
  :where(.css-dev-only-do-not-override-1wazalj).ant-radio-wrapper
  span {
  font-size: 14px;
}
.modalinner
  :where(.css-dev-only-do-not-override-1wazalj).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #d5af68;
  background-color: #d5af68;
}
.modalinner
  .ant-radio-wrapper:hover
  :where(.css-dev-only-do-not-override-1wazalj).ant-radio-wrapper,
.modalinner
  :where(.css-dev-only-do-not-override-1wazalj).ant-radio-wrapper:hover
  .ant-radio-inner {
  border-color: #d5af68;
}
:where(.css-dev-only-do-not-override-1wazalj).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  height: 40px;
  display: flex;
  align-items: center;
  text-align: left;
}
:where(.css-dev-only-do-not-override-1wazalj).ant-form-item-with-help
  .ant-form-item-explain {
  text-align: left;
}
.codeInput {
  width: 75px;
  z-index: 5;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 12px 6px #ccc;
  box-shadow: 1px 1px 12px 6px #ccc;
  padding: 5px;
  height: 75px;
  line-height: 20px;
  text-align: center;
  font-size: 20px;
  color: #000;
  border: 0;
}
.sidebar h4.ant-list-item-meta-title {
  color: #535353 !important;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}
.sidebar h4.ant-list-item-meta-title:hover {
  color: #bf9343 !important;
}
.productcard {
  text-align: center;
  margin: 0 0 15px;
  cursor: pointer;
}
.productcard img {
  max-width: 100%;
  margin-bottom: 15px;
  height: 150px !important;
  object-fit: contain;
}
.productcard h5 {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
}

.productcard h6 {
  color: #bf9343;
  font-size: 13px;
  font-weight: 500;
  margin: 5px 0;
  text-align: left;
}
.productprice {
  color: #bf9343;
  font-size: 28px;
  margin: 10px 0;
  font-weight: 800;
  display: block;
}
.colors {
  margin: 0 0 10px;
}
.colorRadio {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  margin: 0 10px 0 0;
  padding: 0 !important;
}
.colors .ant-radio-button-wrapper:not(:first-child)::before,
.sizeRadio::before {
  display: none !important;
}
.colors .ant-radio-button-wrapper-checked,
.sizeRadio .ant-radio-button-wrapper-checked {
  border: 2px solid;
}
.size,
.quantity,
.colors {
  margin: 0 0 10px;
}
.size span,
.quantity span,
.colors span {
  display: block;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px;
}
.sizeRadio {
  width: 42px !important;
  height: 42px !important;
  margin: 0 10px 0 0;
  border-radius: 5px !important;
  padding: 0 !important;
}
.size .ant-radio-group {
  display: flex;
}
.size .ant-radio-group-large .ant-radio-button-wrapper {
  text-align: center !important;
  border: 2px solid #000;
  align-items: center;
  justify-content: center;
}
.size .ant-radio-button-wrapper:hover {
  border-color: #bf9343 !important;
  color: #bf9343 !important ;
}
.size .ant-radio-button-wrapper-checked {
  border-color: #bf9343 !important;
  color: #bf9343 !important ;
}

.quantity-wrapper {
  display: flex;
  justify-content: center;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  max-width: fit-content;
  padding: 4px;
}
.quantity-wrapper span {
  margin: 0;
  font-size: 12px !important;
  color: #fff;
  font-weight: bold;
}
.quantity-wrapper .ant-btn.ant-btn-circle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: 0;
  width: 32px;
  height: 32px;
  margin: 0;
  background-color: #bf9343;
}
.quantity-wrapper .ant-input-number {
  border: 0 !important;
  max-width: 50px;

  font-weight: bold;
}
.quantity-wrapper .ant-input-number-input {
  text-align: center;
}
.quantity-wrapper .ant-input-number-handler-wrap {
  display: none;
}
.productreview h3,
.reviewarea h3 {
  font-size: 24px;
  color: #000000;
  margin: 0 0 15px;
  text-transform: uppercase;
}
.reviewarea h6 {
  font-size: 20px;
  color: #bf9343;
  margin: 0 0 15px;
}
.productreview p {
  color: #333333;
  font-size: 14px;
  text-align: left;
  margin: 0 0 15px;
}
.reviewarea {
  padding: 40px 25px;
  -webkit-box-shadow: 1px 1px 12px 6px #ccc;
  box-shadow: 1px 1px 12px 6px #ccc;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  margin: 0 0 35px;
}
.reviewarea h5 {
  font-size: 18px;
  color: #1e1919;
  margin: 0 0 5px;
  text-align: left;
}
.reviewarea textarea {
  resize: none;
  min-height: 150px;
  margin: 10px 0 25px;
}
.clientreview {
  padding: 25px 20px;
  -webkit-box-shadow: 1px 1px 12px 6px #ccc;
  box-shadow: 1px 1px 12px 6px #ccc;
  background: #fff;
  border-radius: 5px;
  margin: 25px 0;
  box-sizing: border-box;
}
.clientreview h3 {
  color: #2b2541;
  font-size: 14px;
  margin: 15px 0;
  font-weight: 500;
}
.clientreview p {
  font-size: 12px;
  color: #999999;
  margin: 0 0 10px;
  text-align: left;
}
.clientreview h4 {
  font-size: 12px;
  color: #ee743a;
  margin: 0 0 5px;
  text-align: left;
}
.reviewarea .item {
  padding: 0 15px;
}
.orderDetails h6 {
  font-size: 14px;
  font-weight: 800;
  margin: 0 0 5px;
  line-height: 12px;
}
.orderDetails h3 {
  font-size: 22px;
  margin: 25px 0px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 25px;
}

.orderDetails h4 {
  font-size: 16px;
  margin: 0 0 10px;
  color: #bf9343;
}
.orderDetails h5 {
  font-size: 14px;
  margin: 0 0 10px;
  color: #4d4d4d;
}
.orderDetails p {
  font-size: 14px;
  margin: 0 0 25px;
  color: #333333;
  text-align: left;
}
.orderstatus {
  font-size: 12px;
}
.summary {
  border-radius: 8px;
  overflow: hidden;
  margin: 25px 0;
}
.summary h5 {
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a77721+55,d5af68+93,d5af68+93 */
  background: #a77721; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #a77721 55%,
    #d5af68 93%,
    #d5af68 93%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a77721', endColorstr='#d5af68',GradientType=1 ); /* IE6-9 */
  padding: 10px 0;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
}
.summaryinner {
  border: 2px dashed #a97924;
  background: #f8f8f8;
  padding: 25px 20px;
  border-top: 0;
}
.summaryList {
  display: flex;
  justify-content: space-between;
}
.checkout {
  padding: 25px 0;
}
.checkout h3 {
  font-size: 22px;
  margin: 25px 0px;
}

.checkout h6,
.checkout h5 {
  font-size: 18px;
  margin: 0;
  color: #4d4d4d;
}
.AuthFormInput {
  width: 100%;
  font-size: 14px;
  padding: 12px;
}

.AuthFormInputNumber {
  width: 100%;
  font-size: 14px;
  padding: 4px;
}

.ant-select {
  height: 48px !important;
  font-family: "Nunito" !important;
}

.AuthFormInput input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

.steps .ant-steps-icon {
  color: #fff !important;
  top: -13px !important;
  left: -13px !important;
  width: 60px !important;
  height: 60px !important;
  vertical-align: middle;
  background: #dcdbdb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.steps .ant-steps-item-active .ant-steps-icon {
  background: #bf9343;
}
@media screen and (max-width: 1440.9px) {
  :where(.css-dev-only-do-not-override-1wazalj).ant-menu-light
    .ant-menu-item:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ),
  :where(.css-dev-only-do-not-override-1wazalj).ant-menu-light
    > .ant-menu
    .ant-menu-item:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ),
  :where(.css-dev-only-do-not-override-1wazalj).ant-menu-light
    .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ),
  :where(.css-dev-only-do-not-override-1wazalj).ant-menu-light
    > .ant-menu
    .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ) {
    color: #fff !important;
    background-color: #d5af68 !important;
  }
  :where(.css-dev-only-do-not-override-1wazalj).ant-menu-horizontal
    .ant-menu-item,
  :where(.css-dev-only-do-not-override-1wazalj).ant-menu-horizontal
    .ant-menu-submenu {
    padding-inline: 9px;
  }
  .mainMenu.ant-menu-horizontal {
    font-size: 14px;
  }
}

.loginButton {
  font-size: 16px;
  padding: 10px;
  font-family: "Nunito";
  font-weight: bold;
  width: 180px;
}
@media screen and (max-width: 1199.9px) {
  .bannercards,
  .bannercards2 {
    margin: 15px 0;
  }
  .shadowwhitebg {
    margin-left: 20px;
    margin-top: 25px;
  }
}

.loginForm .ant-form-item-label {
  font-weight: bold;
  font-family: "Nunito";
}

.loginForm .ant-input:focus {
  border-color: #b88c3b !important;
  box-shadow: none;
}

.loginForm .ant-input-password:focus {
  border-color: #b88c3b !important;
  box-shadow: none;
}
.loginForm .ant-input-number:focus {
  border-color: #b88c3b !important;
  box-shadow: none;
}

.loginForm .ant-select:focus {
  border-color: #b88c3b !important;
  box-shadow: none;
}

.loginForm .ant-input:hover {
  border-color: #b88c3b !important;
  box-shadow: none;
}

.loginForm .ant-input-password:hover {
  border-color: #b88c3b !important;
  box-shadow: none;
}

.loginForm .ant-input-number:hover {
  border-color: #b88c3b !important;
  box-shadow: none;
}

@media screen and (max-width: 991.9px) {
  .mobileprofile {
    position: absolute;
    bottom: 0;
    padding: 15px 0;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
  .banner .alice-carousel,
  .eventsarea .alice-carousel,
  .featuredarea .alice-carousel {
    margin: 65px auto;
  }
  .banner h1 {
    font-size: 60px;
  }
  .videoarea h2,
  .subscriptionarea h2,
  .featuredarea h2 {
    font-size: 40px;
  }
  .videoarea p,
  .subscriptionarea p,
  .banner p,
  .featuredarea p {
    font-size: 16px;
  }
  .eventsarea h2,
  .rightarea h2 {
    font-size: 30px;
    margin: 0 0 30px;
  }
  .rightarea h4,
  .featuredarea h3 {
    font-size: 18px;
    line-height: 18px;
  }
}

@media screen and (max-width: 767.9px) {
  .mainbtn {
    margin: 10px 0;
  }
  .banner .alice-carousel,
  .eventsarea .alice-carousel,
  .featuredarea .alice-carousel {
    margin: 50px auto;
  }
  .banner h1 {
    font-size: 48px;
  }
  .videoarea h2,
  .subscriptionarea h2,
  .featuredarea h2 {
    font-size: 30px;
  }
  .videoarea p,
  .subscriptionarea p,
  .banner p,
  .featuredarea p {
    font-size: 15px;
  }
  .eventsarea h2,
  .rightarea h2 {
    font-size: 20px;
    margin: 0 0 20px;
  }
  .rightarea h4,
  .featuredarea h3 {
    font-size: 18px;
    line-height: 18px;
  }
}

/* Table Css */
.styledPagination {
  width: max-content;
  border: 1px solid #e6e9ec !important;
  border-radius: 5px;
  font-family: "Poppins", sans-serif !important;
  overflow: hidden;
  height: fit-content !important;
}
.styledPagination * {
  font-family: "Poppins", sans-serif !important;
}
.styledPagination .ant-pagination-prev {
  padding: 5px 20px;
  height: auto;
  color: #b0b0b0;
  margin: 0;
  border-radius: 0;
  font-weight: 300;
}
.styledPagination .ant-pagination-item {
  padding: 5px 10px;
  height: auto;
  font-weight: 300;
  border-radius: 0;
  margin: 0;
  color: #b0b0b0;
  border: none;
  border-left: 1px solid #e6e9ec !important;
}
.styledPagination .ant-pagination-item-active {
  background-color: #bf9343 !important;
  color: white !important;
  border: 1px solid #bf9343 !important;
}
.styledPagination .ant-pagination-item-active * {
  color: white !important;
}
.styledPagination .ant-pagination-item-active:hover {
  background-color: #bf9343 !important;
  border-left: 1px solid #bf9343 !important;
  color: white !important;
}
.styledPagination .ant-pagination-item-active:hover * {
  color: white !important;
}
.styledPagination .ant-pagination-item:hover {
  background-color: #bf9343 !important;
  border: 1px solid #bf9343 !important;
  color: white !important;
}
.styledPagination .ant-pagination-item:hover * {
  color: white !important;
}
.styledPagination .ant-pagination-next {
  padding: 5px 20px;
  height: auto;
  margin: 0;
  border-radius: 0;
  color: #b0b0b0;
  font-weight: 300;
  border-left: 1px solid #e6e9ec !important;
}
.styledPagination .ant-pagination-next:hover {
  background-color: #bf9343;
  border-left: 1px solid #bf9343 !important;
  color: white !important;
}
.styledPagination .ant-pagination-prev:hover {
  background-color: #bf9343;
  color: #fff;
}

.styledTable,
.styledTable2 {
  min-width: 600px !important;
  width: 100% !important;
  border: none;
}
.styledTable table {
  border-collapse: inherit !important;
  border-spacing: 0 15px !important;
}
.styledTable tbody tr {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(236, 236, 236, 0.5),
    0 0px 12px 0 rgba(236, 236, 236, 0.9) !important;
  border-radius: 10px !important;
  margin: 10px !important;
  font-size: 12px !important;
}
.styledTable2 table {
  text-align: center !important;
}
.styledTable2 tr th {
  background-color: #a77721 !important;
  text-align: center !important;
  color: #fff !important;
  text-transform: uppercase;
}
.styledTable .ant-table-cell {
  background: transparent !important;
  border: none !important;
}
.styledTable .ant-table-cell::before {
  background: transparent !important;
}

.filterInput {
  width: 100% !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px;
  margin-bottom: 10px;
}

.mainInput {
  background: #fff;
  font-weight: 400;
  padding: 10px 15px;
  font-size: 14px;
  width: 100%;
  resize: none;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 10px;
  -moz-appearance: none;
}

.scrollHidden {
  /* Hide the scroll bar, but allow scrolling */
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollHidden::-webkit-scrollbar {
  /* Hide WebKit scroll bar */
  display: none;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  margin: auto;
  list-style: none;
}

.page-link {
  position: relative;
  display: flex;
  justify-content: center;
  color: #b78a39;
  margin: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  align-items: center;

  text-decoration: none;
  background-color: #fff;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #b78a39;
  background-color: #e9ecef;
  border-color: #b78a39;
}
.page-link:focus {
  z-index: 3;
  color: #b78a39;
  background-color: #e9ecef;
  outline: 0;
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #b78a39;
}
.page-item.disabled .page-link {
  color: #dadada;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item.disabled .page-link * {
  color: #dadada;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.pcsModal {
}
.landinpage {
  height: 100vh;
  background: url("/public/images/banner.png") no-repeat;
  background-size: cover;
  text-align: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.landinpage h2 {
  font-size: 60px;
  margin: 0 0 25px;
  text-transform: uppercase;
}
.landinpage .mainbtn {
  min-width: 180px;
  margin: 0 15px;
}
.landinpage p {
  font-size: 18px;
  margin: 0 0 35px;
}

.StyledModal .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.ant-menu-title-content::after {
  display: none !important;
}

.StyledModal .ant-modal-content {
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mousepointer {
  cursor: pointer;
}

.fltr-btn {
  background: rgb(213, 175, 104);
  background: linear-gradient(
    180deg,
    rgba(213, 175, 104, 1) 0%,
    rgba(167, 119, 33, 1) 100%
  );
}

.mainInput {
  background: #fff;
  font-weight: 400;
  padding: 10px 15px;
  font-size: 14px;
  color: var(--secondaryColor);
  width: 100%;
  resize: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 99px;
  box-shadow: 0px 2px 4px #00000021;
  -moz-appearance: none;
}

.dashInput {
  width: auto !important;
  background: #fff !important;
  border: 1px solid #e0e0e0 !important;
}

.chartSelectBox .ant-select-selector {
  background: #fff;
  font-weight: 400;
  outline: none !important;
  font-size: 14px;
  color: var(--secondaryColor);
  width: 100%;
  resize: none;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 99px !important;
  box-shadow: 0px 2px 2px #00000021;
  -moz-appearance: none;
}

.chartSelectBox .ant-select-selector:focus {
  outline: 0 !important;
}

.filterDropdown {
  border: none;
  border-radius: 4px;
  box-shadow: 0px 2px 4px #00000021;
  max-width: 300px;
}
.mainLabel {
  font-size: 15px;
  color: #333;
  font-weight: 600;
  word-break: break-word;
  margin: 0;
}

.filterDropdownBody {
  padding: 1rem 1rem;
}

.filterInput {
  width: 100% !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px;
  margin-bottom: 10px;
}

.filterSelectBox .ant-select-selector {
  background: #fff;
  font-weight: 400;
  outline: none !important;
  font-size: 14px;
  color: var(--secondaryColor);
  width: 100%;
  resize: none;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 5px !important;
  box-shadow: 0px 2px 2px #00000021;
  -moz-appearance: none;
}

.customForm .ant-input-compact-item:not(.ant-input-compact-last-item) {
  padding: 9px !important;
}

.ant-radio-wrapper .ant-radio-checked {
  border-color: #b78a39 !important;
  background-color: #b78a39 !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #b78a39 !important;
  background-color: #b78a39 !important;
}

.ant-radio-wrapper .ant-radio-input:focus-visible + .ant-radio-inner {
  outline: 4px solid white !important;
}
